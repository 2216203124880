.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

html,
body {
    text-align: justify;
    font-family: Verdana, Geneva, sans-serif;
    font-size: 17px;
    width: 100%;
}

img {
    max-width: 100%;
}

hr {
    border: none;
}

.fs24 {
    font-size: 24px;
}

.fs32 {
    font-size: 32px;
}

.mt-20 {
    margin-top: -20px;
}

.min-width-80 {
    min-width: 80px;
}

.flex {
    display: flex;
    justify-content: center;
}

.table-container {
    width: 100%;
    overflow-y: auto;
    _overflow: auto;
    margin: 0 0 1em;
}

.table-container::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
}

.table-container::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid #fff;
    background-color: rgba(0, 0, 0, 0.3);
}

.table-center {
    margin: 0 auto;
    border: none;
}

.table-border-red {
    border: 1px solid red;
    margin: auto;
}

.text-center {
    text-align: center !important;
}

.text-indent {
    text-indent: 4%;
}

.bg-red {
    background: red;
}

.bg-yellow {
    background-color: #ff0;
    height: 2px;
}

.bg-ccc {
    background: #ccc;
}

.border-ccc {
    border: 1px solid #ccc;
}

.color-red {
    color: red;
}

.color-white {
    color: #fff;
}

.color-blue {
    color: #00f;
}

.color-green {
    color: green;
}

.max-width-480 {
    max-width: 480px;
}

.bold {
    font-weight: 700;
}

.border-none {
    border: none;
}

@media (max-width: 736px) {
    .desktop {
        display: none;
    }
}
